<template>
  <base-layout :title="title" :backLink="backLink" :reloadable="reloadable">
    <ion-refresher slot="fixed" @ionRefresh="refresh($event)">
      <ion-refresher-content></ion-refresher-content>
    </ion-refresher>
    <ion-grid fixed>
      <ion-row>
        <ion-col size="12">
          <classroom-student :resources="resources"></classroom-student>
        </ion-col>
      </ion-row>
    </ion-grid>
    <ion-infinite-scroll threshold="100px" @ionInfinite="loadData($event)">
      <ion-infinite-scroll-content
        loading-spinner="bubbles"
        loading-text="Loading more data..."
      >
      </ion-infinite-scroll-content>
    </ion-infinite-scroll>
    <!-- <ion-fab vertical="bottom" horizontal="end" slot="fixed">
      <ion-fab-button @click="setCreateModalState(true)">
        <ion-icon :ios="addOutline" :md="addOutline"></ion-icon>
      </ion-fab-button>
    </ion-fab>
    <ion-modal
      :is-open="createModalState"
      @didDismiss="setCreateModalState(false)"
    >
      <base-modal
        title="Add student to class"
        @closeModal="setCreateModalState(false)"
      >
        <create-classroom-student
          :resource="resources.data.sectionStandard"
          @closeModal="setCreateModalState(false)"
        ></create-classroom-student>
      </base-modal>
    </ion-modal> -->
  </base-layout>
</template>

<script>
import BaseLayout from "../../Base/BaseLayout";
// import BaseModal from "../../Base/BaseModal";
// import CreateClassroomStudent from "../../Components/Setup/Classroom/CreateClassroomStudent";
import ClassroomStudent from "../../Components/Setup/Classroom/ClassroomStudent";

import {
  IonGrid,
  IonRow,
  IonCol,
  // IonFab,
  // IonIcon,
  // IonFabButton,
  // IonModal,
  IonInfiniteScroll,
  IonInfiniteScrollContent,
  IonRefresher,
  IonRefresherContent,
} from "@ionic/vue";

import { addOutline } from "ionicons/icons";

export default {
  props: ["class"],

  components: {
    BaseLayout,
    // BaseModal,
    // CreateClassroomStudent,
    ClassroomStudent,
    IonGrid,
    IonRow,
    IonCol,
    // IonFab,
    // IonIcon,
    // IonFabButton,
    // IonModal,
    IonInfiniteScroll,
    IonInfiniteScrollContent,
    IonRefresher,
    IonRefresherContent,
  },

  data() {
    return {
      title: "Academic Class",
      backLink: "setup",
      reloadable: true,

      // createModalState: false,

      addOutline,
    };
  },

  computed: {
    resources() {
      return this.$store.getters["classroom/resources"];
    },
    shouldReload() {
      return this.$store.getters["reload"] === this.$route.name;
    },
  },

  watch: {
    shouldReload(current) {
      if (current) {
        this.refresh();
        this.$store.dispatch("clearReload");
      }
    },
  },

  methods: {
    async sync() {
      const title = this.$store.getters["classroom/title"];
      this.title = title;
    },
    async fetch(refresh = true) {
      await this.$store.dispatch("classroom/resources", {
        resourceId: this.class,
        refresh: refresh,
      });
      this.sync();
    },
    loadData(event) {
      this.fetch(false);
      event.target.complete();
    },
    async refresh(event) {
      await this.$store.dispatch("classroom/resources", {
        resourceId: this.class,
        refresh: true,
      });
      if (event) {
        event.target.complete();
      }
      this.sync();
    },
    // setCreateModalState(state) {
    //   this.createModalState = state;
    // },
  },

  created() {
    this.fetch();
  },
};
</script>

<style scoped>
</style>
