<template>
  <ion-list v-if="resources.data.students != null">
    <ion-list-header class="ion-margin-bottom">
      <h3>Students</h3>
    </ion-list-header>
    <ion-item
      v-for="item in resources.data.students.data"
      :key="item.id"
      :ref="`items-${item.id}`"
      class="hydrated link"
    >
      <ion-label @click="openPopover(item, $event)">
        <h2>Roll: {{ item.roll_no }} - {{ item.user.user_detail.name }}</h2>
      </ion-label>
    </ion-item>
  </ion-list>
  <ion-list v-else>
    <ion-list-header>
      <p>No students found</p>
    </ion-list-header>
  </ion-list>
  <ion-modal
    :is-open="updateModalState"
    @didDismiss="setUpdateModalState(false)"
  >
    <base-modal
      title="Update student record"
      @closeModal="setUpdateModalState(false)"
    >
      <update-classroom-student
        @closeModal="setUpdateModalState(false)"
        :resource="resourceToUpdate"
      ></update-classroom-student>
    </base-modal>
  </ion-modal>

  <ion-popover
    :is-open="popoverState"
    :event="popoverEvent"
    @didDismiss="setPopoverState(false)"
  >
    <base-popover
      @closePopover="setPopoverState(false)"
      :title="selectedResource.user.user_detail.name"
    >
      <ion-item button @click="setResourceToUpdate(selectedResource.id)"
        >Edit</ion-item
      >
    </base-popover>
  </ion-popover>
</template>
<script>
import BaseModal from "../../../Base/BaseModal";
import UpdateClassroomStudent from "../Classroom/UpdateClassroomStudent";
import BasePopover from "../../../Base/BasePopover.vue";
import {
  IonList,
  IonListHeader,
  IonLabel,
  IonItem,
  IonModal,
  IonPopover,
} from "@ionic/vue";

export default {
  props: {
    resources: {
      type: Object,
      required: true,
    },
  },

  components: {
    IonList,
    IonListHeader,
    IonLabel,
    IonItem,
    IonModal,
    IonPopover,
    BasePopover,
    BaseModal,
    UpdateClassroomStudent,
  },
  data() {
    return {
      updateModalState: false,

      popoverState: false,
      popoverEvent: null,
      selectedResource: null,

      resourceToUpdate: {
        sectionStandard: null,
        data: null,
      },
    };
  },
  methods: {
    setUpdateModalState(state) {
      this.updateModalState = state;
    },

    setResourceToUpdate(id) {
      const resource = this.resources.data.students.data.find(
        (resource) => resource.id === id
      );
      this.resourceToUpdate.data = resource;
      this.resourceToUpdate.sectionStandard =
        this.resources.data.sectionStandard;
      this.setUpdateModalState(true);
    },

    openPopover(resource, ev) {
      this.popoverEvent = ev;
      this.setSelectedResource(resource);
      this.setPopoverState(true);
    },

    setPopoverState(state) {
      this.popoverState = state;
    },

    setSelectedResource(resource) {
      this.selectedResource = resource;
    },
  },
};
</script>
